/** @jsx jsx */
import * as moment from 'moment';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Flex, jsx } from 'theme-ui';
import { Themed } from '@theme-ui/mdx';
import { Button } from '../components';
import CustomsClearanceStatus from '../components/CustomsClearanceStatus';
import { Paperclip } from '../components/Icon'; // todo
import Link from '../components/Link';
import { TrackingEvents } from '../components/TrackingParcel';
import * as catalog from '../state/catalog';
import { locNavigate } from '../state/session';
import { updateSingleShipment, archiveShipment, unarchiveShipment } from '../state/shipments';
import * as api from '../utils/api';
import { getTrackingInfo } from '../utils/api';
import { getLocalizedCountryName, isFinlandOrAland } from '../utils/countryCode';
import { downloadFile } from '../utils/fileDownload';
import { formatPrice } from '../utils/formatters';
import { useMomentLocale } from '../utils/getLanguage';
import { capitalize } from '../utils/string';
import ConnectionInfo from '../components/express/ConnectionInfo';
import { getUnpaidCashPayments } from '../utils/shipment';
import { dateToTimezoneHour } from '../utils/date';
import { useDeliveryEstimate } from '../hooks/useDeliveryEstimate';
import { ReturnContext } from '../returnShipment/ReturnContext';
import i18n from '../localization/i18n';

const NOT_RETURNABLE_PRODUCTS = ['11', '13', '21', '35', '36', '60', '61', '62', '64', '65', '66', '67', '86'];

const ShipmentRow = ({ label, value, valueLink, ...props }) => {
  return (
    <Flex>
      <Box sx={{ color: 'black', fontWeight: 'medium', minWidth: '180px' }}>{label}</Box>
      <Box {...props} sx={{ color: 'black', ...props?.sx }}>
        {valueLink ? (
          <Link to={valueLink} sx={{ textDecoration: 'underline' }}>
            {value}
          </Link>
        ) : (
          <>{value}</>
        )}
      </Box>
    </Flex>
  );
};

const ShipmentNumberRow = ({ label, value, valueLink, ...props }) => {
  return (
    <Flex>
      <Box sx={{ color: 'black', fontWeight: 'medium', minWidth: '180px' }}>{label}</Box>
      <Box {...props} sx={{ color: 'black', ...props?.sx }} aria-label={value.split('').join(' ')}>
        <span aria-hidden={true}>{value}</span>
      </Box>
    </Flex>
  );
};

const PinCodeRow = ({ label, value, valueLink, ...props }) => {
  return (
    <Flex>
      <Box sx={{ color: 'black', fontWeight: 'medium', minWidth: '180px' }}>{label}</Box>
      <Box {...props} sx={{ color: 'black', ...props?.sx }} aria-label={value.split('').join(' ')}>
        <span aria-hidden={true}>{value}</span>
      </Box>
    </Flex>
  );
};

const Circle = ({ position, filled }) => {
  return (
    <div
      sx={{
        position: 'absolute',
        bg: filled ? 'primary' : 'white',
        width: '20px',
        height: '20px',
        top: '-9px',
        left: `calc(${position}% - 9px)`,
        borderColor: 'primary',
        borderWidth: '2px',
        borderRadius: '10px',
        borderStyle: 'solid',
      }}
    />
  );
};

const StatusSlider = ({ shipmentStatus, percentOverride, hasHomeDelivery }) => {
  const statusCode = +(shipmentStatus || '00').substr(0, 2);
  let percentComplete;
  if (percentOverride) {
    percentComplete = percentOverride;
  } else if (statusCode >= 60 || (statusCode >= 55 && !hasHomeDelivery)) {
    percentComplete = 100;
  } else if (statusCode >= 47) {
    percentComplete = 80;
  } else if (statusCode >= 40) {
    percentComplete = 60;
  } else if (statusCode >= 35) {
    percentComplete = 40;
  } else if (statusCode >= 10) {
    percentComplete = 20;
  } else {
    percentComplete = 0;
  }

  return (
    <div sx={{ mx: 2, my: 3 }}>
      <div sx={{ position: 'relative', width: '100%', height: '2px', bg: '#ddd' }}>
        <div
          sx={{
            position: 'absolute',
            left: '13px',
            width: `calc(${percentComplete}% - 25px)`,
            height: '2px',
            bg: 'primary',
          }}
        />
        <Circle position={0} />
        <Circle position={100} />
        <Circle position={percentComplete} filled={true} />
      </div>
    </div>
  );
};

const ShipmentState = ({
  lastEvent,
  shipmentStatus,
  agreedDeliveryStartTime,
  agreedDeliveryEndTime,
  storedUntil,
  deliveryIncluded,
  readyForCollect,
  connection,
  timeoutReturnType,
  destinationPlaceName,
  estimatedDeliveryStartTime,
  estimatedDeliveryEndTime,
}) => {
  const { t: translate } = useTranslation();
  const hasHomeDelivery = deliveryIncluded && !readyForCollect;
  const showAgreedDeliveryTime = !!(agreedDeliveryStartTime && agreedDeliveryEndTime);
  const showShelfTime = !hasHomeDelivery && ['55', '56', '57'].includes(shipmentStatus) && storedUntil;
  const isExpress = !!connection;

  const agreedTime = useMemo(() => {
    if (estimatedDeliveryStartTime && estimatedDeliveryEndTime) {
      const date = moment(estimatedDeliveryStartTime).format('ddd D.M.YYYY');
      const startTime = moment(estimatedDeliveryStartTime).format('HH.mm');
      const endTime = moment(estimatedDeliveryEndTime).format('HH.mm');
      return [date, startTime, endTime];
    } else if (agreedDeliveryStartTime && agreedDeliveryEndTime) {
      const date = moment(agreedDeliveryStartTime).format('ddd D.M.YYYY');
      const startTime = moment(agreedDeliveryStartTime).format('HH.mm');
      const endTime = moment(agreedDeliveryEndTime).format('HH.mm');
      return [date, startTime, endTime];
    } else {
      return null;
    }
  }, [estimatedDeliveryEndTime, estimatedDeliveryStartTime, agreedDeliveryStartTime, agreedDeliveryEndTime]);

  return (
    <Box sx={{ my: 4 }}>
      <StatusSlider shipmentStatus={shipmentStatus} hasHomeDelivery={hasHomeDelivery} />
      <Flex>
        {!isExpress ? (
          <Box sx={{ flexGrow: 1, color: 'black' }}>
            <p>
              {timeoutReturnType
                ? translate(`account.parcelDetails.returnSelected.${timeoutReturnType?.toLowerCase()}`, {
                    pickupPoint: destinationPlaceName,
                  })
                : ''}
            </p>
            {lastEvent ? lastEvent : ''}
          </Box>
        ) : null}

        {isExpress && (
          <>
            <Box sx={{ flexGrow: 1 }}>{lastEvent ? lastEvent : ''}</Box>
            {/* TODO: Custom textes */}
          </>
        )}
        {showAgreedDeliveryTime ? (
          <Box sx={{ textAlign: 'right' }}>
            {translate('account.parcelDetails.agreedTime')}
            <br />
            <span sx={{ fontWeight: 'medium' }}>
              {agreedTime?.[0]}{' '}
              <>
                {agreedTime?.[1]}-{agreedTime?.[2]}
              </>
            </span>
          </Box>
        ) : (
          showShelfTime && (
            <Box sx={{ textAlign: 'right', maxWidth: '160px' }}>
              <span
                dangerouslySetInnerHTML={{
                  __html: translate('account.parcelDetails.storedUntil', {
                    date: `<b>${moment(storedUntil).format('D.M.YYYY')}</b>`,
                  }),
                }}
              />
            </Box>
          )
        )}
      </Flex>
    </Box>
  );
};

const ProductBox = ({ productId, sizeCode, productDescription, parcelIndex }) => {
  const { t: translate } = useTranslation();
  return (
    <Flex
      sx={{
        alignItems: 'center',
        justifyItems: 'center',
      }}
    >
      {productId && !productId.includes('RePack') && (
        <img sx={{ ml: 0, mr: 3, maxWidth: 64 }} src={catalog.imagePathForProduct(sizeCode)} />
      )}
      {parcelIndex ? (
        <span sx={{ fontWeight: 'medium', margin: 'auto auto auto 0' }}>
          {translate('account.parcelDetails.parcel')} {parcelIndex}
        </span>
      ) : productDescription ? (
        <span sx={{ fontWeight: 'medium', margin: 'auto auto auto 0' }}>{productDescription}</span>
      ) : null}
      <div sx={{ flexGrow: 1 }} />
    </Flex>
  );
};

const DimensionBox = ({ dimension, value }) => {
  const { t: translate } = useTranslation();
  const unit = {
    height: 'cm',
    width: 'cm',
    length: 'cm',
    weight: 'kg',
  };

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        alignItems: 'center',
        justifyItems: 'center',
        minWidth: '30px',
        textAlign: 'center',
      }}
    >
      <div sx={{ fontWeight: 'medium', lineHeight: 'heading' }}>
        {value} {unit[dimension]}
      </div>
      <div sx={{ fontSize: 1 }}>{translate(`account.parcelDetails.${dimension}`)}</div>
    </Flex>
  );
};

const Spacer = () => {
  return <div sx={{ color: 'gray', px: [2, null, 3] }}>&times;</div>;
  // return <div sx={{ my: 3, width: '1px', bg: '#ddd' }} />;
};

export const Parcel = (props) => {
  const { t: translate, i18n } = useTranslation();
  let {
    productCode,
    productId,
    parcelIndex,
    sizeCode,
    shippingWeight,
    shipmentWeight,
    additionalServices,
    length,
    width,
    height,
    receiverCountry,
    //customsStatus,
    customsPayment,
    override,
  } = props;

  const customsStatus = false;

  const dispatch = useDispatch();
  const language = i18n.language;
  const products = useSelector((state) => state.catalog.products);
  useEffect(() => {
    // todo? get abroad products, if receiverCountry != FI, or just show Mittatietoja ei saatavilla ?
    if (!products || products.length === 0) {
      dispatch(catalog.getProducts());
    }
  }, [dispatch, products]);

  let productDescription = '';
  let product = products.filter((p) => p.id === productId)[0];
  if (!product && sizeCode) {
    product = products.filter((p) => p.sizeCode === sizeCode)[0];
    if (!override) {
      override = {
        weightClass: shippingWeight,
      };
    }
  }

  if (sizeCode) {
    if (product) {
      productDescription = sizeCode + catalog.weightText4product({ ...product, ...(override || {}) }, translate);
    } else {
      productDescription = sizeCode;
    }
    if (receiverCountry && !isFinlandOrAland(receiverCountry)) {
      productDescription = productDescription + ', ' + getLocalizedCountryName(receiverCountry, language);
    } else if (['95K', '96K', '97K'].includes(productCode)) {
      productDescription = productDescription + ' ulkomaan paketti';
    }
    (additionalServices || []).forEach((s) => {
      let serviceName = s.description;
      if (['JA', 'KJ', 'EK', 'VK'].includes(s.additionalServiceCode)) {
        serviceName = translate(`buyParcel.services.${s.additionalServiceCode}.title`);
      }
      if (serviceName) {
        productDescription = productDescription + ', ' + serviceName;
      }
    });
  } else if (!parcelIndex) {
    productDescription = catalog.productCode2Name[productCode] || translate('account.parcelDetails.parcel');
    // todo additionalServices?
  }

  let estimatedSize;
  if (product && product.maxSize && !length && !width && !height) {
    estimatedSize = {
      length: product.maxSize.depth,
      width: product.maxSize.width,
      height: product.maxSize.height,
      weight: shippingWeight,
    };
  }

  const dimensions = [];
  ['height', 'width', 'length', 'weight'].forEach((dim) => {
    const value = estimatedSize ? estimatedSize[dim] : props[dim];
    if (value) {
      if (dimensions.length) {
        dimensions.push(<Spacer key={dimensions.length} />);
      }
      dimensions.push(<DimensionBox key={dim} dimension={dim} value={value} />);
    }
  });

  const isProduction = process.env.GATSBY_ACTIVE_ENV === 'production';

  return (
    <>
      {dimensions.length ? (
        <Flex
          sx={{
            bg: 'blueLighter',
            borderRadius: 2,
            mb: 2,
            p: 3,
            alignItems: 'center',
            justifyContent: 'space-between',
            flexWrap: ['wrap', null, 'nowrap'],
          }}
        >
          <ProductBox {...{ productId, sizeCode, productDescription, parcelIndex }} />
          <Flex
            sx={{
              mt: [2, 0],
              alignItems: 'center',
              fontSize: [1, 2],
            }}
          >
            {dimensions}
          </Flex>
        </Flex>
      ) : (
        <Flex
          sx={{
            bg: 'blueLighter',
            borderRadius: 2,
            mb: 2,
            p: 3,
            alignItems: 'center',
          }}
        >
          <ProductBox {...{ productId, sizeCode, productDescription, parcelIndex }} />
          <div sx={{ ml: 2 }}>{translate('account.parcelDetails.sizeNotAvailable')}</div>
        </Flex>
      )}
      <div sx={{ flexGrow: 1 }} />
      {customsStatus && !isProduction && <CustomsClearanceStatus parcel={props} />}
      {estimatedSize && <div sx={{ textAlign: 'right', mr: 3 }}>{translate('account.parcelDetails.estimated')}</div>}
    </>
  );
};

const LoadReceiptButton = ({ shipmentNumber }) => {
  const { t: translate } = useTranslation();
  const onClick = useCallback(async () => {
    const response = await api.getParcelReceipt(shipmentNumber);
    downloadFile(response);
  }, [shipmentNumber]);

  return (
    <div
      sx={{
        border: '1px solid gray',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        mb: 3,
        px: 3,
      }}
    >
      <Paperclip sx={{ color: 'gray', transform: 'scale(0.75)' }} />
      <div sx={{ flexGrow: 1, mx: 2, color: 'black' }}>{translate('account.parcelDetails.receipt')}</div>
      <Button variant="plain" onClick={onClick}>
        {translate('account.parcelDetails.receiptLoad')}
      </Button>
    </div>
  );
};

export default ({ shipment, goBack }) => {
  const {
    shipmentNumber,
    shipmentDate,
    shipmentStatus,
    storedUntil,
    deliveryTime,
    deliveryTimeToken,
    activationCode,
    senderName,
    senderStreetAddress,
    senderPostalCode,
    senderCity,
    departurePlaceId,
    departurePlaceName,
    receiverName,
    receiverStreetAddress,
    receiverPostalCode,
    receiverCity,
    receiverCountry,
    destinationPlaceId,
    destinationPlaceName,
    agreedDeliveryStartTime,
    agreedDeliveryEndTime,
    productCode,
    sizeCode,
    shippingWeight,
    shipmentWeight,
    shipmentVolume,
    parcelCount,
    parcels,
    additionalServices,
    direction,
    transferToDelivery,
    transferToPickupPoint,
    postponeReturn,
    deliveryPinCode,
    deliveryIncluded,
    readyForCollect,
    //customsStatus,
    mrn,
    customsWarehouseCode,
    customsWarehouseArrivalDate,
    payments,
    woltDelivery,
    notReceive,
    unpaidCashPayments,
    timeoutReturnType,
    canTransferDeliveryToServicePoint,
    isDeliveryParcel,
    canReturn,
    canUpgrade,
    canChangeDeliveryOptions,
    ...other
  } = shipment;

  const customsStatus = false;

  const dispatch = useDispatch();
  const { t: translate, i18n } = useTranslation();
  const language = i18n.language;
  const { parcelNumber: trackedParcelNumber, parcel: trackingData = {} } = useSelector((state) => state.tracking);
  useMomentLocale();

  const deliveryEstimate = useDeliveryEstimate(shipment) || shipment.deliveryEstimate;
  const formattedEstimate = formatDeliveryEstimate(shipment, deliveryEstimate);

  const clearanceFeePaid = payments?.some(
    (payment) => payment.chargeCode === '801' && payment.paymentStatus === 'PAID'
  );

  const parcelArrived = !!customsStatus && mrn && customsWarehouseCode && customsWarehouseArrivalDate;
  const parcelNotArrivedUnpaid = !!customsStatus && !parcelArrived && !clearanceFeePaid;
  const parcelArrivedUnpaid = !!customsStatus && parcelArrived && !clearanceFeePaid;
  const parcelArrivedDoClearance = !!customsStatus && parcelArrived && clearanceFeePaid;

  const [tracking, setTracking] = useState(
    [(trackingData || {}).parcelNumber, trackedParcelNumber].includes(shipmentNumber) ? trackingData : null
  );
  useEffect(() => {
    if (!tracking) {
      (async () => {
        const response = await getTrackingInfo(shipmentNumber, language);
        // parcelNumber, departureStation, productCategory, trackingEvents
        setTracking(response);

        if (response.detailsForLoggedInUser && response.detailsForLoggedInUser.direction === direction) {
          dispatch(updateSingleShipment(direction, response.detailsForLoggedInUser));
        }
      })();
    }
  }, [tracking, shipmentNumber, language, direction, dispatch]);
  const lastTrackingEvent = tracking && tracking.trackingEvents && tracking.trackingEvents[0];
  let lastEventDescription = lastTrackingEvent && lastTrackingEvent.description;
  const movedToDelivery = tracking && !!tracking.newShipmentNumber;
  const transferedToPickupPoint = tracking && !!tracking.transferShipmentNumber;
  const woltAvailable = tracking?.detailsForLoggedInUser?.woltAvailable || false;
  const isProduction = process.env.GATSBY_ACTIVE_ENV === 'production';

  if (movedToDelivery) {
    lastEventDescription = (
      <>
        {translate('account.parcelDetails.delivery')}{' '}
        <Link to={`/tracking?parcelNumber=${tracking.newShipmentNumber}`} sx={{ textDecoration: 'underline' }}>
          {tracking.newShipmentNumber}
        </Link>
        .
      </>
    );
  } else if (transferedToPickupPoint) {
    lastEventDescription = (
      <>
        {translate('account.parcelDetails.transferedTo')}{' '}
        <Link to={`/tracking?parcelNumber=${tracking.transferShipmentNumber}`} sx={{ textDecoration: 'underline' }}>
          {tracking.transferShipmentNumber}
        </Link>
        .
      </>
    );
  }

  if (tracking && tracking.returnShipmentNumber) {
    lastEventDescription = (
      <>
        {translate('account.parcelDetails.return')}{' '}
        <Link to={`/tracking?parcelNumber=${tracking.returnShipmentNumber}`} sx={{ textDecoration: 'underline' }}>
          {tracking.returnShipmentNumber}
        </Link>
        .
      </>
    );
  }

  if (parcelNotArrivedUnpaid) {
    lastEventDescription = translate('account.parcelDetails.customsParcelArriving');
  }

  if (parcelArrivedUnpaid) {
    lastEventDescription = translate('account.parcelDetails.customsParcelArrivedPayFee');
  }
  if (parcelArrivedDoClearance) {
    lastEventDescription = translate('account.parcelDetails.customsParcelArrived');
  }

  const [pickupPoint, setPickupPoint] = useState();
  useEffect(() => {
    if (pickupPoint || !destinationPlaceId) {
      return;
    }
    setPickupPoint({});
    (async () => {
      const response = await api.getServicePointById(destinationPlaceId, [], language);
      if (response[0]) {
        setPickupPoint(response[0]);
      }
    })();
  }, [pickupPoint, setPickupPoint, destinationPlaceId, language]);

  const [orderDetails, setOrderDetails] = useState(null);

  const [orderDetailsLoading, setOrderDetailsLoading] = useState(false);

  const checkOrderDetails =
    (direction === 'sent' && (+shipmentStatus < 45 || (!isProduction && +shipmentStatus < 60))) ||
    (!!unpaidCashPayments?.length && direction === 'received' && !timeoutReturnType);

  useEffect(() => {
    if (!checkOrderDetails) {
      return;
    }
    (async () => {
      setOrderDetailsLoading(true);
      // if shipment is a returning shipment, we need order details from the original shipment
      const orderDetailsShipmentNumber =
        unpaidCashPayments?.length && direction === 'received' ? shipment?.originalShipmentNumber : shipmentNumber;
      const response = await api.getOrderDetailsForShipment(orderDetailsShipmentNumber);
      if (response && !response.notFound) {
        setOrderDetails(response);
      }
      setOrderDetailsLoading(false);
    })();
  }, [checkOrderDetails, shipmentNumber, unpaidCashPayments, setOrderDetails]);

  const hasHomeDelivery = deliveryIncluded && !readyForCollect;
  let destination = null;
  if (destinationPlaceId && !hasHomeDelivery && !isDeliveryParcel) {
    if (pickupPoint && pickupPoint.officeName) {
      const { officeCode, officeName, officeStreetAddress, officePostalCode, officeCity } = pickupPoint;
      destination = (
        <>
          {officeName}, {officeStreetAddress}, {officePostalCode} {officeCity}
          <br />
          <Link to={`/palvelupisteet?id=${officeCode}`} sx={{ textDecoration: 'underline' }}>
            {translate('account.parcelDetails.showOnMap')}
          </Link>
        </>
      );
    } else {
      destination = destinationPlaceName ? capitalize(destinationPlaceName) : destinationPlaceId;
    }
  } else if (receiverStreetAddress && receiverStreetAddress) {
    destination = `${receiverStreetAddress}, ${receiverPostalCode} ${(receiverCity && capitalize(receiverCity)) || ''}`;
  }

  const alreadyTransfered = useMemo(() => {
    return !!additionalServices?.find((s) => s.additionalServiceCode === 'STN');
  }, [additionalServices]);

  const arraPakettiProducts = ['60', '61', '62', '64', '65', '66', '67'];
  const dueReturnPayments = useMemo(() => getUnpaidCashPayments(shipment), [shipment]);
  const isKotijakelu = ['34', '34S', '35', '35S', '36'].includes(productCode);
  const validStatusForDeliveryTime = +shipmentStatus >= 40 && +shipmentStatus <= 59 && +shipmentStatus !== 45;

  const canSelectDeliveryTime =
    direction === 'received' &&
    !readyForCollect &&
    !!deliveryTimeToken &&
    !agreedDeliveryStartTime &&
    !woltDelivery &&
    !notReceive &&
    isKotijakelu &&
    validStatusForDeliveryTime;

  const canChangeUnattendedDelivery = canChangeDeliveryOptions && !shipment?.delivery && !shipment?.notReceive;

  const canBuyDelivery =
    !movedToDelivery &&
    !transferedToPickupPoint &&
    direction === 'received' &&
    transferToDelivery &&
    !notReceive &&
    !woltDelivery &&
    !alreadyTransfered &&
    !arraPakettiProducts.includes(productCode) &&
    !isDeliveryParcel;

  const canBuyStorageTime =
    !movedToDelivery &&
    !woltDelivery &&
    !transferedToPickupPoint &&
    !notReceive &&
    (!isProduction || direction == 'received') &&
    postponeReturn &&
    !arraPakettiProducts.includes(productCode) &&
    !isDeliveryParcel;

  const isExpressParcel = productCode === '20' || productCode === '21';

  const canChangePickupPoint =
    !movedToDelivery &&
    !woltDelivery &&
    !transferedToPickupPoint &&
    direction === 'received' &&
    transferToPickupPoint &&
    !notReceive &&
    !isExpressParcel &&
    !timeoutReturnType &&
    !dueReturnPayments &&
    !arraPakettiProducts.includes(productCode) &&
    !isDeliveryParcel;

  const unallowedFreePickupChangeProducts = ['86S', '34', '35', '30', '13', '80S', '37', '37K', ...arraPakettiProducts];

  const canChangePickupPointForFree =
    !movedToDelivery &&
    !woltDelivery &&
    !transferedToPickupPoint &&
    direction === 'received' &&
    !unallowedFreePickupChangeProducts.includes(productCode) &&
    !notReceive &&
    +shipmentStatus >= 2 &&
    +shipmentStatus <= 19 &&
    !timeoutReturnType &&
    !dueReturnPayments &&
    !isExpressParcel &&
    !isDeliveryParcel &&
    parcels?.length <= 1;

  const canOrderWolt =
    !movedToDelivery &&
    !woltDelivery &&
    !transferedToPickupPoint &&
    direction === 'received' &&
    !notReceive &&
    readyForCollect &&
    !agreedDeliveryStartTime &&
    woltAvailable;

  const canTrackDelivery = !!shipment?.deliveryTime?.canTrackDelivery;

  const canLoadReceipt = direction === 'sent' && +shipmentStatus > 2;
  const canUpdateRecipient = !!orderDetails && !unpaidCashPayments?.length && (receiverCountry || 'FI') === 'FI';
  const orderRow = orderDetails && orderDetails.shipments.find((sh) => sh.shipmentNumber === shipmentNumber);
  const canUpgradeShipment = direction === 'sent' && canUpgrade && orderRow && (orderRow.upgrades || []).length > 0;
  const canWoltTrack = woltDelivery && woltDelivery?.tracking;
  const canReturnShipment =
    direction === 'received' &&
    +shipmentStatus === 60 &&
    tracking &&
    !tracking.returnShipmentNumber &&
    !movedToDelivery &&
    canReturn &&
    !(productCode || '').endsWith('K') &&
    !(productCode || '').endsWith('S') &&
    !NOT_RETURNABLE_PRODUCTS.includes(productCode); // todo*/

  const canViewPackingInstructions = direction === 'sent' && +shipmentStatus <= 10;
  const orderActivationCode = orderDetails?.activationCode;
  const isExpress = !!shipment?.connection;
  const isDelivered = shipment.status === 'delivered';
  const canSeeActivationCode = (!!activationCode || !!orderActivationCode) && (!isExpress || direction !== 'received');
  const isPickupOrdered = direction === 'sent' && !!orderDetails?.pickup;

  const archived = useSelector((state) => state.shipments.archived);
  const isArchived = !!archived[shipmentNumber];
  const canArchive = true && !isArchived;
  const isAutomat = useMemo(() => {
    return ['EASYPACK'].includes(pickupPoint?.officeType);
  }, [pickupPoint]);

  const toAgreeDelivery = useCallback(() => {
    dispatch(locNavigate('/sovijakelu', `?shipment=${shipmentNumber}&token=${deliveryTimeToken}`));
  }, [dispatch, shipmentNumber, deliveryTimeToken]);

  const buyDelivery = useCallback(() => {
    dispatch(
      locNavigate('/delivery', '', {
        state: { shipmentNumber, sender: senderName, destinationPlaceName },
      })
    );
  }, [dispatch, shipmentNumber, senderName, destinationPlaceName]);

  const buyWolt = useCallback(() => {
    dispatch(
      locNavigate('/wolt-delivery', '', {
        state: {
          shipment,
        },
      })
    );
  }, [dispatch, shipment]);

  const handleDeliveryToServicePoint = useCallback(() => {
    dispatch(locNavigate('/delivery-to-service-point', `?shipment=${shipmentNumber}&token=${deliveryTimeToken}`));
  }, [dispatch, shipmentNumber, deliveryTimeToken]);

  const packingInstructions = useCallback(() => {
    dispatch(
      locNavigate(
        '/my-pages/packing-instructions',
        `?${new URLSearchParams({
          return: productCode === '81',
          pickup: isPickupOrdered,
          express: !!isExpress,
        })}`
      )
    );
  }, [dispatch, shipmentNumber, isPickupOrdered, isExpress]);

  const buyStorageTime = useCallback(() => {
    dispatch(
      locNavigate('/storage-time', '', {
        state: {
          direction,
          shipmentNumber,
          receiver: receiverName,
          sender: senderName,
          destinationPlaceName,
          pickupPoint,
          storedUntil,
        },
      })
    );
  }, [dispatch, shipmentNumber, senderName, destinationPlaceName, pickupPoint, storedUntil]);

  const updateRecipient = useCallback(() => {
    dispatch(
      locNavigate('/update-shipment', '', {
        state: { shipment, orderDetails, pickupPoint },
      })
    );
  }, [dispatch, shipment, orderDetails, pickupPoint]);

  const upgradeSize = useCallback(() => {
    dispatch(
      locNavigate('/upgrade-size', '', {
        state: { shipmentNumber },
      })
    );
  }, [dispatch, shipmentNumber]);

  const handleReturnSelection = useCallback(() => {
    dispatch(
      locNavigate('/return-to-customer', `?shipment=${shipment.shipmentNumber}&token=${shipment?.shipmentToken}`, '', {
        state: {
          order: null,
        },
      })
    );
  }, [shipment, tracking, orderDetails, isProduction]);

  const doArchive = useCallback(() => {
    dispatch(archiveShipment(shipmentNumber));
  }, [dispatch, shipmentNumber]);
  const doUnarchive = useCallback(() => {
    dispatch(unarchiveShipment(shipmentNumber));
  }, [dispatch, shipmentNumber]);

  const { setShipment: setReturnShipment } = useContext(ReturnContext);

  const returnShipment = useCallback(() => {
    dispatch(locNavigate('/return-shipment', `?shipment=${shipment.shipmentNumber}`));
  }, [dispatch, setReturnShipment, shipment]);

  const transferShipment = useCallback(() => {
    dispatch(
      locNavigate('/transfer', '', {
        state: {
          shipmentNumber,
          freeTransfer: +shipment?.shipmentStatus <= 19,
          sender: senderName,
          currentDestination: '' + destinationPlaceId,
        },
      })
    );
  }, [dispatch, shipmentNumber, senderName, destinationPlaceId]);

  const productId = sizeCode ? (productCode + sizeCode + shippingWeight).replace(/\s+/g, '') : '';
  const shelveCodes = (parcels || [])
    .map((p) => p.shelveCode)
    .filter((c) => !!c)
    .join(', ');

  const chooseDeadline = useMemo(() => {
    if (!shipment?.shipmentDate) {
      return null;
    }
    return moment(shipment.shipmentDate).tz('Europe/Helsinki').add(7, 'days').format('DD.MM.YYYY');
  }, [shipment]);

  return (
    <div>
      <Box>
        <Button onClick={goBack} variant="plain" sx={{ color: 'primary' }}>
          {translate('buyParcel.backButton')}
        </Button>
      </Box>

      <Themed.h1>
        {direction === 'sent' ? translate('account.parcels.sent1') : translate('account.parcels.received1')}
      </Themed.h1>

      {!!isExpress && (
        <Box sx={{ bg: 'blueLighter', p: 2, mb: 2, borderRadius: 2 }}>
          <Box>
            {isDelivered ? translate('buyParcel.express.delivered') : null}
            {!isDelivered
              ? translate('buyParcel.express.info', {
                  stop: [shipment.connection.toPlace?.placeName, shipment.connection.toPlace?.stopAreaName]
                    .filter(Boolean)
                    .join(' - '),
                  time: dateToTimezoneHour(shipment.connection.toPlace?.dateTime),
                  company: shipment.connection.companies
                    ? ` (${[...shipment.connection.companies.map((it) => it.name), shipment.connection.line?.number]
                        .filter(Boolean)
                        .join(', ')})`
                    : '',
                })
              : null}
          </Box>
        </Box>
      )}

      {direction === 'sent' ? (
        <ShipmentRow
          label={translate('account.parcelDetails.recipient')}
          value={capitalize(receiverName)}
          sx={{ fontWeight: 'medium' }}
        />
      ) : (
        <>
          <ShipmentRow
            label={translate('account.parcelDetails.sender')}
            value={capitalize(senderName)}
            sx={{ fontWeight: 'medium' }}
          />
          {formattedEstimate ? (
            <ShipmentRow label={translate('account.parcelDetails.arrival')} value={formattedEstimate} />
          ) : null}
        </>
      )}
      <ShipmentNumberRow label={translate('account.parcelDetails.shipmentNumber')} value={shipmentNumber} />
      <ShipmentRow label={translate('account.parcelDetails.destination')} value={destination} />
      {canSeeActivationCode && (
        <ShipmentRow
          label={translate('account.parcelDetails.activationCode')}
          value={activationCode || orderActivationCode}
          sx={{ fontWeight: 'medium' }}
        />
      )}

      {deliveryPinCode && pickupPoint?.officeType && (
        <PinCodeRow
          label={
            isAutomat
              ? translate('account.parcelDetails.deliveryPinCode')
              : translate('account.parcelDetails.deliveryCode')
          }
          value={deliveryPinCode}
          sx={{ fontWeight: 'medium' }}
        />
      )}
      {shelveCodes && !hasHomeDelivery && (
        <ShipmentRow
          label={translate('account.parcelDetails.shelveCode')}
          value={shelveCodes}
          sx={{ fontWeight: 'medium' }}
        />
      )}

      {!!isExpress && (
        <Box sx={{ mt: 2 }}>
          <ConnectionInfo connection={shipment.connection} />
        </Box>
      )}

      <ShipmentState {...shipment} lastEvent={lastEventDescription} />

      {productCode === '58' && direction === 'received' && !isDelivered && (
        <div sx={{ color: 'black', mb: 4, flexGrow: 1 }}>{translate('account.parcelDetails.freightInfo')}</div>
      )}

      {isPickupOrdered && (
        <Box
          sx={{
            mb: 4,
            mx: [-3, 0],
            p: 3,
            borderRadius: [0, 2],
            bg: 'blueLighter',
            maxWidth: 900,
          }}
        >
          {translate('account.parcelDetails.pickupGuidance')}
        </Box>
      )}

      {canSelectDeliveryTime && <AgreeDeliveryButtons shipment={shipment} />}

      {canChangeUnattendedDelivery && !canSelectDeliveryTime && (
        <AgreeUnattendedDeliveryButtons
          shipment={shipment}
          canTransferDeliveryToServicePoint={canTransferDeliveryToServicePoint}
          handleDeliveryToServicePoint={handleDeliveryToServicePoint}
        />
      )}

      {canLoadReceipt && <LoadReceiptButton shipmentNumber={shipmentNumber} />}

      {dueReturnPayments > 0 && !timeoutReturnType && !orderDetailsLoading ? (
        <div sx={{ bg: 'blueLighter', px: 3, py: 1, borderRadius: 1, my: 4 }}>
          <p>{translate('return.notCollected', { deadline: chooseDeadline })}</p>
          <Button sx={{ mb: 2 }} variant="secondary" onClick={handleReturnSelection}>
            {translate('return.title')}
          </Button>
        </div>
      ) : null}

      <div sx={{ flexGrow: 1 }}>
        <div sx={{ fontWeight: 'medium', mb: 2 }}>{translate('account.parcelDetails.includes')}</div>
        {parcels &&
          parcels.map((p, i) => (
            <Parcel
              key={p.parcelNumber}
              productId={productId}
              parcelIndex={parcels.length > 1 ? i + 1 : null}
              {...shipment}
              {...p}
            />
          ))}
      </div>

      <Flex sx={{ mt: 4, width: '280px', flexDirection: 'column' }}>
        {canViewPackingInstructions && (
          <Button sx={{ mb: 2 }} variant="secondary" onClick={packingInstructions}>
            {translate('account.parcelDetails.packagingInstructions.seeInstructions')}
          </Button>
        )}
        {canTransferDeliveryToServicePoint && !canChangeUnattendedDelivery && !shipment?.notReceive && (
          <Button sx={{ mb: 2 }} variant="secondary" onClick={handleDeliveryToServicePoint}>
            {translate('delivery.agree.deliveryToServicePoint.button')}
          </Button>
        )}
        {canTrackDelivery && (
          <Button sx={{ mb: 2 }} variant="secondary" onClick={toAgreeDelivery}>
            {translate('account.parcelDetails.trackDelivery')}
          </Button>
        )}
        {canOrderWolt && (
          <Button sx={{ mb: 2 }} variant="secondary" onClick={buyWolt}>
            {translate('wolt.button')}
          </Button>
        )}
        {canBuyDelivery && (
          <Button sx={{ mb: 2 }} variant="secondary" onClick={buyDelivery}>
            {translate('account.parcelDetails.buyDelivery', {
              price: formatPrice(catalog.DELIVERY_START_PRICE),
            })}
          </Button>
        )}
        {canWoltTrack && (
          <Button sx={{ mb: 2 }} variant="secondary" as={Link} to={{ link: woltDelivery.tracking, external: true }}>
            {translate('account.parcelDetails.trackDelivery')}
          </Button>
        )}
        {canBuyStorageTime && (
          <Button sx={{ mb: 2 }} variant="secondary" onClick={buyStorageTime}>
            {translate('account.parcelDetails.buyStorageTime', {
              price: formatPrice(catalog.STORAGE_TIME_START_PRICE),
            })}
          </Button>
        )}
        {(canChangePickupPoint || canChangePickupPointForFree) && (
          <Button sx={{ mb: 2 }} variant="secondary" onClick={transferShipment}>
            {translate('account.parcelDetails.changePickupPoint')}
          </Button>
        )}
        {canUpdateRecipient && (
          <Button sx={{ mb: 2 }} variant="secondary" onClick={updateRecipient}>
            {translate('account.parcelDetails.updateRecipient')}
          </Button>
        )}
        {canReturnShipment && (
          <Button sx={{ mb: 2 }} variant="secondary" onClick={returnShipment}>
            {translate('account.parcelDetails.returnShipment')}
          </Button>
        )}
        {canUpgradeShipment && (
          <Button sx={{ mb: 2 }} variant="secondary" onClick={upgradeSize}>
            {translate('account.parcelDetails.upgradeSize')}
          </Button>
        )}
        {canArchive && (
          <Button sx={{ mb: 2 }} variant="secondary" onClick={doArchive}>
            {translate('account.parcelDetails.archive')}
          </Button>
        )}
        {isArchived && (
          <Button sx={{ mb: 2 }} variant="secondary" onClick={doUnarchive}>
            {translate('account.parcelDetails.unarchive')}
          </Button>
        )}
      </Flex>
      {tracking && (tracking.trackingEvents || []).length > 1 && (
        <TrackingEvents events={tracking && tracking.trackingEvents} />
      )}
    </div>
  );
};

const AgreeUnattendedDeliveryButtons = ({
  shipment,
  canTransferDeliveryToServicePoint,
  handleDeliveryToServicePoint,
}) => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const { shipmentNumber, deliveryTimeToken } = shipment || {};

  const handleGoto = useCallback(
    (action) => {
      const actionParam = action ? `&action=${action}` : '';
      const params = `?shipment=${shipmentNumber}&token=${deliveryTimeToken}${actionParam}`;
      dispatch(locNavigate('/sovijakelu', params));
    },
    [dispatch, shipmentNumber, deliveryTimeToken]
  );

  return (
    <div sx={{ mb: 4, display: 'flex', flexDirection: 'row', gap: 3, flexWrap: 'wrap' }}>
      {canTransferDeliveryToServicePoint && (
        <Button variant="secondary" onClick={handleDeliveryToServicePoint}>
          {translate('delivery.agree.deliveryToServicePoint.button')}
        </Button>
      )}
      <Button variant="secondary" onClick={() => handleGoto('delivery-unattended')}>
        {translate('account.parcelDetails.unattendedDelivery')}
      </Button>
      <Button variant="secondary" onClick={() => handleGoto('not-receive')}>
        {translate('account.parcelDetails.toNotReceive')}
      </Button>
    </div>
  );
};

const AgreeDeliveryButtons = ({ shipment }) => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();

  const { shipmentNumber, deliveryTimeToken, pickupAvailableForDelivery } = shipment || {};

  const handleGoto = useCallback(
    (action) => {
      const actionParam = action ? `&action=${action}` : '';
      const params = `?shipment=${shipmentNumber}&token=${deliveryTimeToken}${actionParam}`;
      dispatch(locNavigate('/sovijakelu', params));
    },
    [dispatch, shipmentNumber, deliveryTimeToken]
  );

  return (
    <div sx={{ mb: 4, display: 'flex', flexDirection: 'row', gap: 3, flexWrap: 'wrap' }}>
      <Button variant="secondary" onClick={() => handleGoto('time')}>
        {translate('account.parcelDetails.toAgreeDelivery')}
      </Button>
      {pickupAvailableForDelivery && (
        <Button variant="secondary" onClick={() => handleGoto('service-point')}>
          {translate('account.parcelDetails.toServicePoint')}
        </Button>
      )}
      <Button variant="secondary" onClick={() => handleGoto('not-receive')}>
        {translate('account.parcelDetails.toNotReceive')}
      </Button>
    </div>
  );
};

const formatHours = (timestamp) => {
  const date = moment(timestamp).tz('Europe/Helsinki');
  return date.format(date.minutes() === 0 ? 'HH' : 'HH:mm');
};

const formatDeliveryEstimate = (shipment, deliveryEstimate) => {
  const language = i18n.language;

  let estimateString;

  const hasAccurateEstimate = !!shipment?.estimatedDeliveryStartTime && !!shipment?.estimatedDeliveryEndTime;

  if (!deliveryEstimate && !hasAccurateEstimate) {
    return null;
  }

  if (hasAccurateEstimate) {
    const hours = `${language === 'fi' ? 'klo ' : ''}${formatHours(shipment.estimatedDeliveryStartTime)}-${formatHours(
      shipment.estimatedDeliveryEndTime
    )}`;
    const weekday = capitalize(
      moment(shipment.estimatedDeliveryStartTime).tz('Europe/Helsinki').locale(language).format('dddd')
    );
    const day = moment(shipment.estimatedDeliveryStartTime).locale(language).format('D.M.YYYY');
    estimateString = `${weekday} ${day} ${hours}`;
  } else if (deliveryEstimate) {
    const weekday = capitalize(moment(deliveryEstimate.delivery).tz('Europe/Helsinki').locale(language).format('dddd'));
    const day = capitalize(moment(deliveryEstimate.delivery).locale(language).format('D.M.YYYY'));

    estimateString = `${weekday} ${day}`;
  } else {
    // no-op
  }
  return estimateString;
};
